// 客戶
export const sysCustomize = {
  bovia: `Bovia`,
  npa: 'NPA'
}
export const getCustomizeValue = (str) => {
  const key = str.toLowerCase()
  return key
}

// 客製功能
export const euCustomizeFunction = {
  park: 0,
}
